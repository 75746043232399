.info-bar--wrapper {
  padding: 0;
}

.info-bar {
  position: relative;
  display: block;
  inline-size: 100%;
  padding: 18px 20px 18px 56px;
  color: var(--theme-white) !important;
  font-weight: 500;
  font-size: $font-size-4;
  background-color: var(--theme-brown-700);
  border: 0;
  border-radius: 4px;

  &:hover {
    background-color: var(--theme-brown-600);
  }

  .zmdi {
    position: absolute;
    inset-block-start: 13px;
    inset-inline-start: 18px;
    font-size: $font-size-8;
  }

  .content-toggler--open & {
    border-end-end-radius: 0;
    border-end-start-radius: 0;
  }
}

.info-box__cta {
  margin-inline-start: 3px;
  border-block-end: 2px solid var(--theme-white-a70);

  .info-bar:hover & {
    border-block-end-color: var(--theme-white);
  }
}

.info-bar-details {
  padding: 18px;
  border: 3px solid var(--theme-brown-700);
  border-block-start: none;
  border-end-end-radius: 4px;
  border-end-start-radius: 4px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 20px 0 15px;
    font-weight: 500;
    font-size: $font-size-5;
  }

  p,
  ul {
    margin: 0 0 .8em;
    padding: 0;
    font-size: $font-size-3;

    &:last-child {
      margin-block-end: 0;
    }
  }

  ul {
    padding-inline-start: 30px;
  }
}

.info-bar--slim {
  padding: 6px 0 6px 46px;
  font-weight: 500;
  font-size: $font-size-3;
  background: var(--theme-brown-500);
  border-radius: 0;

  .zmdi {
    inset-block-start: 7px;
    font-size: $font-size-5;
  }
}

.info-bar--bright {
  color: var(--theme-brown-800) !important;
  background: var(--theme-gold-400);

  a {
    color: var(--theme-brown-800);
    border-block-end: 2px solid var(--theme-gold-100);

    &:hover {
      color: var(--theme-gold-800);
      border-block-end: 2px solid var(--theme-gold-800);
    }
  }

  &:hover {
    background: var(--theme-gold-300);
  }
}

.info-bar--gentle-danger {
  inline-size: 97%;
  margin: 20px;
  color: var(--theme-red-700) !important;
  background: var(--theme-red-100-a30);
  border: 2px solid var(--theme-red-100);
  border-radius: 5px;

  h2 {
    margin: -5px 0 5px;
    color: var(--theme-red-700);
  }

  a {
    color: var(--theme-red-700);
    border-block-end: 2px solid var(--theme-red-400);

    &:hover {
      color: var(--theme-red-500);
      border-block-end: 2px solid var(--theme-red-100);
    }
  }

  &:hover {
    background: var(--theme-red-100-a30);
  }
}

.info-bar--gentle-info {
  margin-block-end: 20px;
  color: var(--theme-brown-500) !important;
  background: var(--theme-gold-100-a50);
  border: 2px solid var(--theme-gold-400);
  border-radius: 5px;

  a {
    color: var(--theme-brown-500);
    border-block-end: 2px solid var(--theme-gold-800);

    &:hover {
      color: var(--theme-brown-900);
      border-block-end: 2px solid var(--theme-gold-400);
    }
  }

  &:hover {
    background: var(--theme-gold-100-a50);
  }
}
