/* stylelint-disable order/order, no-duplicate-selectors */

@use "sass:list";
@use "sass:map";

$color-map: (
  "white": #fff,
  "black": #000,

  "brown-100": #c0bbb6,
  "brown-200": #aaa095,
  "brown-300": #736b63,
  "brown-400": #655e59,
  "brown-500": #5b554f,
  "brown-600": #4a4541,
  "brown-700": #38332f,
  "brown-800": #292522,
  "brown-900": #1c1917,
  
  "grey-100": #fcfaf8,
  "grey-200": #faf7f5,
  "grey-300": #f5f2ef,
  "grey-400": #efebe7,
  "grey-500": #e1dcd4,
  "grey-600": #d0c9bf,
  
  "purple-100": #f6ebff,
  "purple-200": #e4cfff,
  "purple-300": #c78fff,
  "purple-400": #ad57ff,
  "purple-500": #9325ff,
  "purple-600": #8407ff,
  "purple-700": #60c,
  "purple-800": #4f009c,
  "purple-900": #3d0079,
  
  "gold-100": #ffe8a4,
  "gold-200": #ffdf77,
  "gold-300": #ffd34e,
  "gold-400": #ffce36,
  "gold-500": #ffc500,
  "gold-600": #dfa700,
  "gold-700": #c29100,
  "gold-800": #7a6115,
  
  "green-100": #abe3ce,
  "green-200": #73e3bb,
  "green-300": #47dca6,
  "green-400": #28dd9b,
  "green-500": #18b47b,
  "green-600": #13885d,
  "green-700": #0f6a49,
  
  "red-100": #ff8f96,
  "red-200": #ff5760,
  "red-300": #ff2533,
  "red-400": #ff0716,
  "red-500": #cd000c,
  "red-600": #9c000a,
  "red-700": #790007,
  
  "teal-100": #a5cfe8,
  "teal-200": #77b6dd,
  "teal-300": #38a5eb,
  "teal-400": #169bef,
  "teal-500": #087bc3,
  "teal-600": #075d93,
  "teal-700": #064873,
  
  "blue-100": #ebecff,
  "blue-200": #cfd7ff,
  "blue-300": #a7a2ec,
  "blue-400": #7c73e3,
  "blue-500": #3e30f4,
  "blue-600": #1d0cfa,
  "blue-700": #0f00cd,
  "blue-800": #0c019b,
  "blue-900": #0a0178,
);

$opacity-map: (
  "white": [10, 40, 50, 70, 80],
  "black": [10, 30, 40, 50, 80],
  "brown-200": [70],
  "brown-500": [20],
  "brown-600": [20, 50],
  "brown-700": [20],
  "brown-800": [10, 20, 30, 50, 60, 70, 90],
  "grey-600": [10, 20, 30, 60],
  "gold-100": [50],
  "green-500": [30],
  "red-100": [30],
  "teal-100": [50]
);

:root {
  // Theme colours
  @each $name, $color in $color-map {
    --theme-#{$name}: #{$color};
    @if map.has-key($opacity-map, $name) {
      @each $alpha in map.get($opacity-map, $name) {
        --theme-#{$name}-a#{$alpha}: #{rgba($color, $alpha: calc($alpha / 100))};
      }
    }
  }

  // colours by use
  --theme-text-body-color: var(--theme-brown-800);
  --theme-text-link-color: var(--theme-blue-800);
  --theme-text-link-hover-color: var(--theme-blue-500);
  --theme-text-link-focus-color: var(--theme-blue-500);
  --theme-primary-color: var(--theme-purple-700);
  --editable-area-highlight-color: var(--theme-grey-200);

  // Layout
  --page-region-header-width: 18vw;

  // Navigation
  --navigation-tooltip-text-color: var(--theme-white);
  --navigation-tooltip-background-color: var(--theme-brown-500);
  --navigation-primary-text-color: var(--theme-white);
  --navigation-primary-background: var(--theme-brown-700);
  --navigation-primary-background-selected: var(--theme-brown-800);
  --navigation-primary-background-submenu: var(--theme-brown-800);
  --navigation-primary-background-hover: var(--theme-brown-600);
  --navigation-primary-separator-color: var(--theme-brown-600);
  --navigation-primary-scrollbar-color: var(--theme-white-a10);
  --navigation-primary-toggle-caret-color: transparent;
  --navigation-secondary-text-color: var(--theme-brown-800);
  --navigation-secondary-background: var(--theme-grey-300);
  --navigation-secondary-background-selected: var(--theme-grey-500);
  --navigation-secondary-background-hover: var(--theme-grey-500);
  --navigation-secondary-background-submenu: var(--theme-grey-400);
  --navigation-secondary-separator-color: var(--theme-grey-500);
  --navigation-secondary-scrollbar-color: var(--theme-brown-800-a10);
  --navigation-secondary-toggle-caret-color: var(--theme-brown-800);
}
