// By default a flash message is displayed as the "notice" level
.flash-message {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  color: var(--theme-brown-800);
  font-weight: 600;
  font-size: $font-size-5;
  line-height: 1.5;
  background-color: var(--theme-gold-300);

  a {
    color: var(--theme-brown-800);
    border-color: var(--theme-gold-600);

    &:hover,
    &:focus {
      color: var(--theme-brown-800) !important;
      border-color: var(--theme-brown-800) !important;
    }
  }

  &::before {
    @include icon("info");

    display: block;
    inline-size: 1.6em;
    color: var(--theme-gold-700);
    font-size: $font-size-7;
    text-align: start;
  }
}

.flash-message__content {
  flex: 1;
  padding: 6px 0;
}

.flash-message__heading {
  margin: 0;
  font-weight: 600;
  font-size: $font-size-5;
  line-height: 1.5;
}

.flash-message__items {
  margin: 0;
  padding: 6px 0 0 2em;
}

.flash-message--success {
  background-color: var(--theme-green-300);

  a {
    border-color: var(--theme-green-500);
  }

  &::before {
    color: var(--theme-green-700);
    content: icon-char("check-circle");
  }
}

.flash-message--alert, // For Devise flash message
.flash-message--failure {
  background-color: var(--theme-red-200);

  a {
    border-color: var(--theme-red-500);
  }

  &::before {
    color: var(--theme-red-700);
    content: icon-char("alert-triangle");
  }
}
