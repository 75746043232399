
.browser-message {
  display: none; // Hidden by default, shown by the JS if the supported browser check fails
}

.unsupported-browser-message {
  display: none; // Hidden by default, shown by the JS if the supported browser check fails
  margin: 24px 24px 0;
  padding: 18px;
  background-color: var(--theme-grey-100);
  border: 3px solid var(--theme-red-500);
  border-radius: 6px;

  p {
    margin: .5em 0;
    padding: 0;
    font-size: $font-size-4;
    line-height: 1.3;
  }
}

.unsupported-browser-message__heading {
  margin: 0 0 1em;
  font-weight: 600;
  font-size: $font-size-5;
}

.unsupported-browser-message__browser-list {
  margin: 0;
  padding: 0;
  font-size: $font-size-4;
  line-height: 1.6;
  list-style-position: inside;

  a {
    font-weight: 700;
  }
}

/* Fixes for older pages */

/* Works overview page */
[data-ruby-controller-name="works"][data-ruby-action-name="show"] {
  .browser-message,
  .unsupported-browser-message {
    margin-inline-end: 25%;
  }
}