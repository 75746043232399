@use "sass:list"; // https://sass-lang.com/documentation/modules/list#index
// From: https://www.smashingmagazine.com/2014/06/sassy-z-index-management-for-complex-layouts/
@function z($list, $element) {
  $z-index: list.index($list, $element);

  @if $z-index {
    @return $z-index - 1; // Minus 1, so that our z-indexes start at 0 (In SCSS indexes start at 1, not 0)
  } @else {
    @error 'There is no item "#{$element}" in this list; choose one of: #{$list}';
  }
}

@function icon-char($icon) {
  @if map-has-key($icons, $icon) {
    @return map-get($icons, $icon);
  } @else {
    @error 'There is no icon "#{$icon}" in this list; choose one of: #{map-keys($icons)}';
  }
}

@mixin button($line-height: 36px, $padding: 15px) {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-block: 0;
  padding-inline: $padding;
  font-weight: 500;
  font-size: $font-size-3;
  font-family: $system-fonts;
  line-height: $line-height;
  white-space: nowrap;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  border-radius: 4px;
  transition: none;

  .zmdi {
    margin-inline-end: 9px;
    color: inherit !important;
    font-size: $font-size-7;
  }
}

@mixin icon($icon: null) {
  -webkit-font-smoothing: auto;
  font-weight: 400;
  font-family: Material-Design-Iconic-Font; // stylelint-disable-line font-family-no-missing-generic-family-keyword
  font-style: normal;
  line-height: 1;
  text-align: center;

  @if ($icon) {
    content: icon-char($icon);
  }
}

@mixin plain-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin plain-button {
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
}

@mixin text-content {
  p {
    margin-block: 0 .6em;
    margin-inline: 0;
    padding: 0;
    font-size: $font-size-4;
    line-height: 1.5;

    &:last-child {
      margin: 0;
    }
  }
}

@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    block-size: 0;
    visibility: hidden;
    content: ".";
  }
}

@mixin tooltip {
  position: absolute;
  display: block;
  padding-block: 0;
  padding-inline: 8px;
  color: var(--theme-white);
  font-size: $font-size-1;
  line-height: 2.2;
  white-space: nowrap;
  background-color: var(--theme-brown-400);
  border-radius: 2px;
  opacity: 0;
  transition: none;
  pointer-events: none;
}

@mixin tooltip-visible {
  opacity: .9;
  transition: all .2s ease-in-out;
}

@mixin plain-link {
  border-block-end: 0 !important;
}

@mixin checkbox-input(
  $size:             18px,
  $border-width:     2px,
  $border-color:     #{var(--theme-brown-300)},
  $background-color: #{var(--theme-white)}
) {
  position: relative;
  display: block;
  block-size: $size;
  inline-size: $size;
  margin: 0;
  padding: 0;
  background: $background-color;
  border: $border-width solid $border-color;
  border-radius: 3px;
}

@mixin checkbox-mark(
  $icon:         null,
  $size:         18px,
  $border-width: 2px,
  $color:        #{var(--theme-white)}
) {
  @include icon($icon);

  $actual_size: $size - ($border-width * 2);

  -webkit-font-smoothing: antialiased; // Fix very thin icons in Chrome
  position: absolute;
  inset-block-start: $border-width;
  inset-inline-start: $border-width;
  block-size: $actual_size;
  inline-size: $actual_size;
  color: $color;
  font-size: $actual_size + 2px;
  line-height: $actual_size;
  text-align: center;
}

// Most of this code from: https://codepen.io/CreativeJuiz/pen/BiHzp
@mixin custom-checkbox(
  $size:                      18px,
  $border-width:              2px,
  $checked-border-color:      #{var(--theme-brown-700)},
  $checked-background-color:  #{var(--theme-brown-700)},
  $disabled-border-color:     #{var(--theme-brown-100)},
  $disabled-background-color: #{var(--theme-grey-400)},
  $disabled-checkmark-color:  #{var(--theme-brown-100)},
  $focused-border-color:      #{var(--theme-brown-800)},
  $hovered-border-color:      #{var(--theme-brown-800)}
) {
  position: relative;
  display: block;
  block-size: $size;
  inline-size: $size;
  min-inline-size: $size;
  overflow: hidden;

  label {
    position: absolute;
    inset: 0;
    margin: 0;
  }

  // Hide the actual checkbox
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    inset-block-start: -$size;
    inset-inline-start: -9999px;
  }

  // Base for label styling
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    cursor: pointer;
  }

  // Checkbox aspect
  [type="checkbox"]:not(:checked) + label::before,
  [type="checkbox"]:checked + label::before {
    @include checkbox-input(
      $size:         $size,
      $border-width: $border-width
    );

    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    content: "";
  }

  [type="checkbox"]:checked + label::before {
    background-color: $checked-background-color;
    border-color: $checked-border-color;
  }

  // Checked mark aspect
  [type="checkbox"]:not(:checked) + label::after,
  [type="checkbox"]:checked + label::after {
    @include checkbox-mark(
      $icon:         "tick",
      $size:         $size,
      $border-width: $border-width
    );
  }

  // Checked mark aspect changes
  [type="checkbox"]:not(:checked) + label::after {
    transform: scale(0);
    opacity: 0;
  }

  [type="checkbox"]:checked + label::after {
    transform: scale(1);
    opacity: 1;
  }

  // Disabled checkbox
  [type="checkbox"]:disabled:not(:checked) + label::before,
  [type="checkbox"]:disabled:checked + label::before {
    background-color: $disabled-background-color !important;
    border-color: $disabled-border-color !important;
    box-shadow: none;
    cursor: default !important;
  }

  [type="checkbox"]:disabled:checked + label::after {
    color: $disabled-checkmark-color !important;
    cursor: default !important;
  }

  /* accessibility */
  [type="checkbox"]:checked:focus + label::before,
  [type="checkbox"]:not(:checked):focus + label::before {
    border-color: $focused-border-color;
  }

  /* hover style just for information */
  label:hover::before {
    border-color: $hovered-border-color !important;
  }
}

@mixin navigation-aware-breakpoint($breakpoint) {
  // A medium amount of space available
  .global-navigation-is-collapsed & {
    @include media(">#{$global-navigation-width-collapsed + $local-navigation-width + $breakpoint}") {
      @content;
    }
  }

  // A medium amount of space available
  .local-navigation-is-collapsed & {
    @include media(">#{$global-navigation-width + $local-navigation-width-collapsed + $breakpoint}") {
      @content;
    }
  }

  // A medium amount of space available
  .expandable-local-navigation-is-collapsed & {
    @include media(">#{$global-navigation-width + $local-navigation-width-collapsed + $breakpoint}") {
      @content;
    }
  }

  // A large amount of space available
  .global-navigation-is-collapsed.local-navigation-is-collapsed & {
    @include media(">#{$global-navigation-width-collapsed + $local-navigation-width-collapsed + $breakpoint}") {
      @content;
    }
  }

  // Full page width is available in the modal window
  .modal-window & {
    @include media(">#{$breakpoint}") {
      @content;
    }
  }

  // A small amount of space available
  @include media(">#{$global-navigation-width + $local-navigation-width + $breakpoint}") {
    @content;
  }
}

@mixin sr-only {
  // If you set these attributes you want them to hide the element visually, hence the `!important`s
  position: absolute !important;
  block-size: 1px !important;
  inline-size: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
}

// Theme
@import "lib/theme";

// Breakpoints
$breakpoints: (
  small: 500px,
  medium: 700px,
  large: 900px,
  big: 1200px,
  huge: 1600px,

  // Breakpoints for use in height queries
  short: 700px,
  tall: 900px
);

// Icons
// View them here: https://zavoloklom.github.io/material-design-iconic-font/icons.html
// Get code from here: app/assets/stylesheets/vendor/_material-design-iconic-font.scss
$icons: (
  alert-circle: "\f1f1",
  alert-circle-o: "\f1f0",
  alert-triangle: "\f1f4",
  block: "\f119",
  check-circle: "\f269",
  chevron-down: "\f2f9",
  chevron-up: "\f2fc",
  chevron-right: "\f2fb",
  cloud-outline: "\f21d",
  cloud-off: "\f21b",
  comment-alert: "\f25a",
  comment-outline: "\f261",
  comment-text: "\f263",
  copy: "\f237",
  download: "\f220",
  edit: "\f158",
  file: "\f223",
  info-outline: "\f1f7",
  info: "\f1f8",
  long-arrow-right: "\f301",
  more: "\f19b",
  search: "\f1c3",
  tick: "\f26b"
);

// Global navigation
$global-navigation-width: 230px;
$global-navigation-width-collapsed: 48px;

// Local navigation
$local-navigation-width: 220px;
$local-navigation-width-collapsed: 48px;

// Page regions
$page-region-breakpoint: 1400px;

// Fonts
$system-fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$monospace-font: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;

$font-size-1: 10px;
$font-size-2: 12px;
$font-size-3: 14px;
$font-size-4: 16px;
$font-size-5: 18px;
$font-size-6: 20px;
$font-size-7: 24px;
$font-size-8: 30px;
$font-size-9: 36px;

// Z-index layers

// Based on this technique: https://www.smashingmagazine.com/2014/06/sassy-z-index-management-for-complex-layouts/
// At the moment the z-* entries are direct translations of the originally set z-indexes
// Eventually we'll set-up clearly defined interface layers, for now: HACKS
// Talk to Andy, he thought this was a good idea
$page-layers: (
  base,
  z-1,
  z-2,
  z-3,
  z-4,
  z-5,
  z-9,
  popovers,
  z-10,
  z-11,
  inline-editor,
  save-buttons,
  nav-bar,
  add-buttons,
  header,
  side-panels,
  page-header,
  z-15,
  z-1000,
  z-1030,
  z-1031,
  z-1050,
  z-1070,
  z-9999,
  z-99999,
  z-1000000,
  quick-search,
  navigation-tooltip,
  dropdown-menu,
  modal-backdrop,
  modal,
  modal-popovers,
  select-input-menu,
  scrollable-table-wrapper,
  autosuggest-menu,
  drop-target,
);

@import "vendor/include_media";
@import "vendor/material-design-iconic-font";

@import "lib/variables";
@import "lib/mixins";

@import "components/flash_messages";
@import "components/info_bar";
@import "components/raised_button";
@import "components/server_error";
@import "components/unsupported_browser_message";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  color: var(--theme-text-body-color);
  font-family: $system-fonts;
  background-color: var(--theme-grey-300);
}

a {
  color: var(--theme-blue-800);
  font-weight: 700;
  text-decoration: none;
  border-block-end: 3px solid var(--theme-blue-300);
  outline: 0;

  &:focus, &:hover {
    color: var(--theme-blue-500);
    border-block-end: 3px solid var(--theme-blue-500);
  }
}

.header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-block-start: 50px;
}

.container {
  max-inline-size: 448px;
  margin-block: 0;
  margin-inline: auto;
  padding-block: 50px;
  padding-inline: 24px;
}

.form-group {
  margin-block: 10px 15px;
  margin-inline: 0;
}

.flash-messages {
  margin-block-end: 50px;
}

.unsupported-browser-message {
  margin-block: 0 30px;
  margin-inline: 0;
}

.form-wrapper {
  display: block;
}

.form-controls {
  margin-block-start: 45px;
}

.form-controls__label {
  margin-block: 0 12px;
  margin-inline: 0;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.flash-message {
  &:first-child {
    border-start-start-radius: 6px;
    border-start-end-radius: 6px;
  }

  &:last-child {
    border-end-start-radius: 6px;
    border-end-end-radius: 6px;
  }
}

.help-block {
  margin-block-start: 10px;
  color: var(--theme-red-200);
  font-weight: 700;
  font-size: $font-size-5;
}

input {
  box-sizing: border-box;
  inline-size: 100%;
  margin-inline-end: 8px;
  padding-block: 8px;
  padding-inline: 10px;
  color: var(--theme-brown-600);
  font: inherit;
  font-size: $font-size-5;
  border: 2px solid var(--theme-grey-600);
  border-radius: 6px;
  outline: none;

  &:focus {
    border: 2px solid var(--theme-primary-color);
  }
}

label {
  display: block;
  margin-block: 12px 5px;
  margin-inline: 0;
  font-weight: 700;
}

.form__field--error .form__label {
  color: var(--theme-red-600);
}

.form__input,
.form__select {
  .form__field--error & {
    border-color: var(--theme-red-200);

    &:hover {
      border-color: var(--theme-red-300);
    }

    &:focus {
      border-color: var(--theme-red-500);
    }
  }
}

.form__error {
  margin-block: 3px 0;
  margin-inline: 0;
  padding: 0;
  color: var(--theme-red-300);
  font-weight: 500;
  font-size: 16px;
}

li {
  list-style-type: none;
}

.actions {
  margin-block-start: 50px;
  text-align: center;

  a {
    margin: 5px;
  }
}

.notices {
  margin-block-start: 50px;
  color: var(--theme-brown-600);
  text-align: center;
}

.image {
  max-inline-size: 350px;
}

.logo-subtitle {
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

// Ensure sign in with SAML button is the correct width
.raised-button {
  box-sizing: border-box;
}

.info-bar--gentle-danger {
  inline-size: initial;
  margin-inline: 0;
}