// stylelint-disable selector-no-qualifying-type
.raised-button,
input.raised-button {
  @include button;
  @include plain-link;

  color: var(--theme-white) !important;
  background-color: var(--theme-purple-700);
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--theme-purple-800);
    box-shadow: 0 1px 2px var(--theme-black-a30) !important;
  }

  &:active {
    background-color: var(--theme-purple-800);
    box-shadow: none !important;
  }
}
// stylelint-enable selector-no-qualifying-type

.raised-button[disabled],
.raised-button--disabled {
  color: var(--theme-brown-100) !important;
  background-color: var(--theme-grey-400) !important;
  box-shadow: none !important;
  cursor: default;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

.raised-button--fixed {
  position: fixed !important; // without this here, the save button on batches wasn't fixed.
  inset-block-start: 20px;
}

.raised-button--small {
  padding: 4px 10px;
  line-height: 1.1;
}

.raised-button--full {
  justify-content: center;
  inline-size: 100%;
}

.raised-button--large {
  font-size: $font-size-5 !important;
  line-height: 2.4 !important;
}

.button--danger.button--danger { // Double-class trick used to avoid having to use `!important`
  background-color: var(--theme-red-400);

  &:hover {
    background-color: var(--theme-red-500);
  }
}