@mixin button($line-height: 36px, $padding: 15px) {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-block: 0;
  padding-inline: $padding;
  font-weight: 500;
  font-size: $font-size-3;
  font-family: $system-fonts;
  line-height: $line-height;
  white-space: nowrap;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  border-radius: 4px;
  transition: none;

  .zmdi {
    margin-inline-end: 9px;
    color: inherit !important;
    font-size: $font-size-7;
  }
}

@mixin icon($icon: null) {
  -webkit-font-smoothing: auto;
  font-weight: 400;
  font-family: Material-Design-Iconic-Font; // stylelint-disable-line font-family-no-missing-generic-family-keyword
  font-style: normal;
  line-height: 1;
  text-align: center;

  @if ($icon) {
    content: icon-char($icon);
  }
}

@mixin plain-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin plain-button {
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
}

@mixin text-content {
  p {
    margin-block: 0 .6em;
    margin-inline: 0;
    padding: 0;
    font-size: $font-size-4;
    line-height: 1.5;

    &:last-child {
      margin: 0;
    }
  }
}

@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    block-size: 0;
    visibility: hidden;
    content: ".";
  }
}

@mixin tooltip {
  position: absolute;
  display: block;
  padding-block: 0;
  padding-inline: 8px;
  color: var(--theme-white);
  font-size: $font-size-1;
  line-height: 2.2;
  white-space: nowrap;
  background-color: var(--theme-brown-400);
  border-radius: 2px;
  opacity: 0;
  transition: none;
  pointer-events: none;
}

@mixin tooltip-visible {
  opacity: .9;
  transition: all .2s ease-in-out;
}

@mixin plain-link {
  border-block-end: 0 !important;
}

@mixin checkbox-input(
  $size:             18px,
  $border-width:     2px,
  $border-color:     #{var(--theme-brown-300)},
  $background-color: #{var(--theme-white)}
) {
  position: relative;
  display: block;
  block-size: $size;
  inline-size: $size;
  margin: 0;
  padding: 0;
  background: $background-color;
  border: $border-width solid $border-color;
  border-radius: 3px;
}

@mixin checkbox-mark(
  $icon:         null,
  $size:         18px,
  $border-width: 2px,
  $color:        #{var(--theme-white)}
) {
  @include icon($icon);

  $actual_size: $size - ($border-width * 2);

  -webkit-font-smoothing: antialiased; // Fix very thin icons in Chrome
  position: absolute;
  inset-block-start: $border-width;
  inset-inline-start: $border-width;
  block-size: $actual_size;
  inline-size: $actual_size;
  color: $color;
  font-size: $actual_size + 2px;
  line-height: $actual_size;
  text-align: center;
}

// Most of this code from: https://codepen.io/CreativeJuiz/pen/BiHzp
@mixin custom-checkbox(
  $size:                      18px,
  $border-width:              2px,
  $checked-border-color:      #{var(--theme-brown-700)},
  $checked-background-color:  #{var(--theme-brown-700)},
  $disabled-border-color:     #{var(--theme-brown-100)},
  $disabled-background-color: #{var(--theme-grey-400)},
  $disabled-checkmark-color:  #{var(--theme-brown-100)},
  $focused-border-color:      #{var(--theme-brown-800)},
  $hovered-border-color:      #{var(--theme-brown-800)}
) {
  position: relative;
  display: block;
  block-size: $size;
  inline-size: $size;
  min-inline-size: $size;
  overflow: hidden;

  label {
    position: absolute;
    inset: 0;
    margin: 0;
  }

  // Hide the actual checkbox
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    inset-block-start: -$size;
    inset-inline-start: -9999px;
  }

  // Base for label styling
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    cursor: pointer;
  }

  // Checkbox aspect
  [type="checkbox"]:not(:checked) + label::before,
  [type="checkbox"]:checked + label::before {
    @include checkbox-input(
      $size:         $size,
      $border-width: $border-width
    );

    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    content: "";
  }

  [type="checkbox"]:checked + label::before {
    background-color: $checked-background-color;
    border-color: $checked-border-color;
  }

  // Checked mark aspect
  [type="checkbox"]:not(:checked) + label::after,
  [type="checkbox"]:checked + label::after {
    @include checkbox-mark(
      $icon:         "tick",
      $size:         $size,
      $border-width: $border-width
    );
  }

  // Checked mark aspect changes
  [type="checkbox"]:not(:checked) + label::after {
    transform: scale(0);
    opacity: 0;
  }

  [type="checkbox"]:checked + label::after {
    transform: scale(1);
    opacity: 1;
  }

  // Disabled checkbox
  [type="checkbox"]:disabled:not(:checked) + label::before,
  [type="checkbox"]:disabled:checked + label::before {
    background-color: $disabled-background-color !important;
    border-color: $disabled-border-color !important;
    box-shadow: none;
    cursor: default !important;
  }

  [type="checkbox"]:disabled:checked + label::after {
    color: $disabled-checkmark-color !important;
    cursor: default !important;
  }

  /* accessibility */
  [type="checkbox"]:checked:focus + label::before,
  [type="checkbox"]:not(:checked):focus + label::before {
    border-color: $focused-border-color;
  }

  /* hover style just for information */
  label:hover::before {
    border-color: $hovered-border-color !important;
  }
}

@mixin navigation-aware-breakpoint($breakpoint) {
  // A medium amount of space available
  .global-navigation-is-collapsed & {
    @include media(">#{$global-navigation-width-collapsed + $local-navigation-width + $breakpoint}") {
      @content;
    }
  }

  // A medium amount of space available
  .local-navigation-is-collapsed & {
    @include media(">#{$global-navigation-width + $local-navigation-width-collapsed + $breakpoint}") {
      @content;
    }
  }

  // A medium amount of space available
  .expandable-local-navigation-is-collapsed & {
    @include media(">#{$global-navigation-width + $local-navigation-width-collapsed + $breakpoint}") {
      @content;
    }
  }

  // A large amount of space available
  .global-navigation-is-collapsed.local-navigation-is-collapsed & {
    @include media(">#{$global-navigation-width-collapsed + $local-navigation-width-collapsed + $breakpoint}") {
      @content;
    }
  }

  // Full page width is available in the modal window
  .modal-window & {
    @include media(">#{$breakpoint}") {
      @content;
    }
  }

  // A small amount of space available
  @include media(">#{$global-navigation-width + $local-navigation-width + $breakpoint}") {
    @content;
  }
}

@mixin sr-only {
  // If you set these attributes you want them to hide the element visually, hence the `!important`s
  position: absolute !important;
  block-size: 1px !important;
  inline-size: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
}
