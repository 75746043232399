// Theme
@import "theme";

// Breakpoints
$breakpoints: (
  small: 500px,
  medium: 700px,
  large: 900px,
  big: 1200px,
  huge: 1600px,

  // Breakpoints for use in height queries
  short: 700px,
  tall: 900px
);

// Icons
// View them here: https://zavoloklom.github.io/material-design-iconic-font/icons.html
// Get code from here: app/assets/stylesheets/vendor/_material-design-iconic-font.scss
$icons: (
  alert-circle: "\f1f1",
  alert-circle-o: "\f1f0",
  alert-triangle: "\f1f4",
  block: "\f119",
  check-circle: "\f269",
  chevron-down: "\f2f9",
  chevron-up: "\f2fc",
  chevron-right: "\f2fb",
  cloud-outline: "\f21d",
  cloud-off: "\f21b",
  comment-alert: "\f25a",
  comment-outline: "\f261",
  comment-text: "\f263",
  copy: "\f237",
  download: "\f220",
  edit: "\f158",
  file: "\f223",
  info-outline: "\f1f7",
  info: "\f1f8",
  long-arrow-right: "\f301",
  more: "\f19b",
  search: "\f1c3",
  tick: "\f26b"
);

// Global navigation
$global-navigation-width: 230px;
$global-navigation-width-collapsed: 48px;

// Local navigation
$local-navigation-width: 220px;
$local-navigation-width-collapsed: 48px;

// Page regions
$page-region-breakpoint: 1400px;

// Fonts
$system-fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$monospace-font: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;

$font-size-1: 10px;
$font-size-2: 12px;
$font-size-3: 14px;
$font-size-4: 16px;
$font-size-5: 18px;
$font-size-6: 20px;
$font-size-7: 24px;
$font-size-8: 30px;
$font-size-9: 36px;
