.server-error {
  .card &,
  .cardless & {
    padding: 24px;

    @include media(">medium") {
      padding: 42px;
    }
  }
}

.server-error__heading {
  margin: 0 0 24px;
  font-size: $font-size-7;

  @include media(">medium") {
    font-size: $font-size-8;
  }
}

.server-error__content {
  p {
    padding: 0;
    font-size: $font-size-3;
    line-height: 1.4;

    &:last-child {
      margin-block-end: 0;
    }

    @include media(">medium") {
      font-size: $font-size-4;
    }
  }

  ul {
    margin-block-end: 18px;
    padding-inline-end: 36px;
    font-size: $font-size-3;
    line-height: 1.4;

    &:last-child {
      margin-block-end: 0;
    }

    @include media(">medium") {
      font-size: $font-size-4;
    }
  }
}
